<template>
    <div class="mt-2 relative bg-neutral-700 text-zinc-500 text-xs"
         :class="borderColor">
        <!-- Input Field -->
        <input
            :id="id"
            :type="currentInputType"
            v-model="inputModel"
            :class="inputClass"
            :required="required"
            :autofocus="autofocus"
            :placeholder="label"
            :autocomplete="autocomplete"
            @input="$emit('update:modelValue', inputModel)"
        />
        
        <!-- Show Password Toggle (visible only for password type) -->
        <div v-if="isPassword" class="absolute inset-y-0 right-0 pr-3 flex items-center text-sm leading-5">
            <button type="button" @click="togglePasswordVisibility">
                {{ showPassword ? 'Cacher' : 'Afficher' }}
            </button>
        </div>
        

        <!-- Error Message -->
        <InputError :message="error"/>
    </div>
</template>

<script setup>
import { ref, computed } from 'vue';
import InputError from '@/Components/InputError.vue';

const props = defineProps({
    modelValue: String,
    label: String,
    id: String,
    type: {
        type: String,
        default: 'text'
    },
    error: String,
    required: Boolean,
    autofocus: Boolean,
    autocomplete: String,
    borderColor: {
        type: String,
        default: 'border-cali-blue'
    }
});
const showPassword = ref(false);

const isPassword = computed(() => {
    return props.type === 'password';
});

const currentInputType = computed(() => {
    return showPassword.value && isPassword.value ? 'text' : props.type;
});

const togglePasswordVisibility = () => {
    showPassword.value = !showPassword.value;
};

const inputModel = ref(props.modelValue);
const inputClass = computed(() => {
    return ['block text-xs w-full bg-transparent border-0 focus:outline-none focus:ring-0 pt-2 pl-2 text-white', {
        'mt-2' : props.label,
        'text-white': !props.error,
        'text-red-500': props.error
    }];
});
</script>
